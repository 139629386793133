<template>
  <FormContainer @submitForm="submit">
    <FeedbackAlert v-if="feedback === false">Errore nel salvataggio dei dati! Riprovare</FeedbackAlert>

    <div class="row">
      <FormInputTextWidget
        class="col-lg-12"
        label="Nome"
        v-model="module.name"
        :errorMsg="errorHash.name"
        @input="removeFieldError('name')"
      />
    </div>

    <div class="row">
      <FormInputTextareaWidget
        class="col-12"
        not-required
        label="Note"
        v-model="module.notes"
      />
    </div>

    <div class="row">
      <div class="col-12 mt-3">
        <button class="btn btn-primary px-5">Salva</button>
      </div>
    </div>

  </FormContainer>
</template>

<script>

import validateMixin from '../../../libs/Form/mixins/validate';

export default {
  name: 'marker-form',
  mixins: [validateMixin],
  components: {
    FormContainer: () => import('../../components/Form/FormContainer'),
    FeedbackAlert: () => import('../../../libs/Feedback/components/Alert'),
    FormInputTextWidget: () => import('../../../libs/Form/components/Inputs/Widgets/FormInputTextWidget'),
    FormInputTextareaWidget: () => import('../../../libs/Form/components/Inputs/Widgets/FormInputTextareaWidget'),
  },
  props: {
    module: {
      type: Object,
      default: () => ({
        name: null,
        notes: null,
      }),
    },
    edit: {
      type: Boolean,
      default: false,
    },
    feedback: {
      type: Boolean,
      default: null,
    },
  },
  methods: {
    removeFieldError (field) {
      this.removeFeedback();
      this.removeError(field);
    },
    removeFeedback () {
      this.$emit('update:feedback', null);
    },
    isValid () {
      this.clearErrors();
      this.removeFeedback();

      if (!this.module.name) {
        this.addError('Inserire il nome del modulo', 'name');
      }

      return !this.hasErrors;
    },
    submit () {
      this.clearErrors();
      this.removeFeedback();

      if (!this.isValid()) {
        return;
      }

      const moduleToReturn = { ...this.module };

      this.$emit('submitForm', moduleToReturn);
    },
  },
};

</script>
